import React, { Component } from "react";
import DropdownMenu from "../Dropdowns/DropdownMenu";
import LocationSearch from "../LocationSearch";
import classnames from "classnames";
import { sendGAEvent } from "../utils/google_analytics";

export default class TopicComparisonSelection extends Component {
  constructor(props) {
    super(props);
    let selectedItem = null;
    if (props.selectedItemId) {
      selectedItem = props.items.find((i) => i.id === props.selectedItemId) || props.items[0];
    }
    this.state = {
      selectedItem: selectedItem
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedItemId === nextProps.selectedItemId) {
      return false;
    }
  }

  items() {
    return this.props.items.map((i) => {
      const classNames = classnames("c-dropdown__item", {
        active: this.state.selectedItem && this.state.selectedItem.id === i.id
      });
      return (
        <a
          key={`item-${i}`}
          className={classNames}
          href="#"
          onClick={this.handleUpdateSelectedItem.bind(this, i)}
        >
          {i.name}
        </a>
      );
    });
  }

  handleUpdateSelectedItem(selectedItem = null, event = null) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ selectedItem: selectedItem });
    if (this.props.callback) {
      this.props.callback(selectedItem, event);
      return;
    } else if (this.props.changeHandler) {
      this.props.changeHandler(selectedItem);
    }
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: "Location picker",
      action: "Location comparison selection",
      label: selectedItem ? selectedItem.name : null
    }});
  }

  render() {
    const { primaryLocation } = this.props;
    const { selectedItem } = this.state;
    return (
      <DropdownMenu
        selectedItem={selectedItem}
        label="Select location for comparison"
        placeholder={!this.state.selectedItem ? "Select a location" : ""}
        ga={{ category: "Location picker", action: "Location comparison", label: "Comparison" }}
        constrained={true}
        theme="comparison"
      >
        <p className="c-dropdown__intro">
          Select a location to compare against {primaryLocation.human_name}:
        </p>
        <LocationSearch
          location={primaryLocation}
          handleUpdateSelectedItem={this.handleUpdateSelectedItem.bind(this)}
        />
      </DropdownMenu>
    );
  }
}
