import { mapFeatureColors, baseMapStyles } from "./settings";
import {
  setBoundsToFeature,
  addMouseEvents,
  customizeFeatureIcon,
  addMapPoints,
  styleSearchResults,
  styleNationalChoropleth
} from "./helpers";
import { fetch } from "whatwg-fetch";
import { uniqeId } from "../utils";
import { querySelectorAll } from "../utils/polyfills";
import Typeahead from "../search/typeahead";

/**
 * Map - Add Google Map instances within elements with class '.j-map'
 *
 * @return NA
 */
export default function Map() {
  querySelectorAll(".j-map").forEach(initMap);
  Typeahead();
}

/**
 * initMap - Init map within an element with class '.j-map'
 *
 * @param  {type} mapEl DOM Node of map container
 * @return NA
 */
function initMap(mapEl) {
  const mapData = mapEl.dataset;
  const { locationId, scope = "state", jsonFileName, points } = mapData;
  const myLatLng = {
    lat: parseFloat(mapData.lat || window.delawareCenter.lat),
    lng: parseFloat(mapData.lon || window.delawareCenter.lon)
  };
  const mapZoom = +mapData.mapZoom || 9;
  const locationSlugs = (mapData.locationSlugs || "").split(",");
  const options = mapData.options ? JSON.parse(mapData.options) : {};
  const showSearchResultsOnMap =
    options.showSearchResultsOnMap != undefined ? options.showSearchResultsOnMap : true;
  const allowLocationPolygonClick =
    options.allowLocationPolygonClick != undefined ? options.allowLocationPolygonClick : true;
  const pointFiles = points ? JSON.parse(points) : [];
  const preservedZoom = options.preservedZoom != undefined ? +options.preservedZoom : false;
  const mapId = mapEl.id || uniqeId();

  let bounds = null;

  if (scope == "state") {
    // Restrict map to a wide rectangle around Delaware
    bounds = window.delawareMapRestrictionCoordinates;
  } else if (scope == "country") {
    // Restrict map to a wide rectangle around the US
    bounds = window.usMapRestrictionCoordinates;
  }

  const mapRestriction = bounds && {
    latLngBounds: bounds,
    strictBounds: true
  };

  const defaultOptions = {
    center: myLatLng,
    zoom: mapZoom,
    restriction: mapRestriction,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControlOptions: {
      mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE]
    },
    streetViewControl: false,
    fullscreenControl: false,
    styles: baseMapStyles()
  };

  const map = new google.maps.Map(mapEl, Object.assign(defaultOptions, options));
  const mapInfoWindows = [];
  const mapMarkers = [];

  // https://developers.google.com/maps/documentation/javascript/datalayer
  map.data.setStyle(showSearchResultsOnMap ? styleSearchResults : styleNationalChoropleth);

  if (scope == "state") {
    // Mask non-delaware areas. We can use any valid geojson here
    map.data.loadGeoJson("/delaware-mask-v3.json"); // Just neighboring states
  } else if (scope == "country") {
    if (jsonFileName) {
      map.data.loadGeoJson(`/${jsonFileName}.json`);
    }
    map.fitBounds(window.usBoundingBoxCoordinates);

    // Style map to replace basemap with plain white backdrop
    const styledMap = new google.maps.StyledMapType([
      {
        stylers: [{ color: "#ffffff" }]
      }
    ]);
    map.mapTypes.set("map_style", styledMap);
  } else {
    console.log("Invalid map scope");
  }

  let primaryLocation = null;
  const locationFeatures = {};

  let promises = [];
  if (showSearchResultsOnMap) {
    let bounds = new google.maps.LatLngBounds();

    locationSlugs.forEach(function (slug) {
      const url = `/locations/${slug}/polygon.json`;
      const geoJsonOptions = { idPropertyName: "ID" };

      promises.push(
        $.getJSON(url, function (data) {
          const feature = map.data.addGeoJson(data, geoJsonOptions)[0];
          feature.setProperty("PRIMARY_LOCATION", slug == locationId);
          if (slug == locationId) primaryLocation = feature;
          feature.getGeometry().forEachLatLng((latlng) => bounds.extend(latlng));
          locationFeatures[slug] = feature;
        })
      );
    });

    Promise.all(promises)
      .then(function () {
        if (preservedZoom) {
          map.setZoom(preservedZoom);
        } else {
          map.fitBounds(bounds);
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  }
  // If pointFiles are given in data attrs, fetch the json files for each
  // and add the points to the map
  if (locationId && pointFiles.length) {
    pointFiles.forEach((file) => {
      let id = file;
      if (file.id) id = file.id;
      fetch(`/locations/${locationId}/${id}.json`)
        .then((response) => response.json())
        .then((data) => {
          addMapPoints(data, map, file.icon, mapInfoWindows, mapMarkers);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error(`Point files for ${locationId} of ${id} failed to load.`);
        });
    });
  }

  if (options.disableMouseEvents === "undefined" || !options.disableMouseEvents) {
    addMouseEvents(map, { allowLocationPolygonClick: allowLocationPolygonClick });
  }

  const mapTriggerEls = document.getElementsByClassName("j-map-trigger");

  for (var i = mapTriggerEls.length - 1; i >= 0; i--) {
    const triggerEl = mapTriggerEls[i];

    triggerEl.addEventListener("mouseenter", function (e) {
      const slug = e.target.dataset.locationSlug;
      const feature = locationFeatures[slug];

      map.data.revertStyle();
      map.data.overrideStyle(primaryLocation, {
        visible: false
      });

      map.data.overrideStyle(feature, {
        fillColor: mapFeatureColors().highlight,
        strokeColor: mapFeatureColors().highlight,
        strokeWeight: 2,
        fillOpacity: 0.2,
        visible: true
      });
    });

    triggerEl.addEventListener("mouseout", function (e) {
      map.data.revertStyle();
    });
  }

  App.ActiveMaps = App.ActiveMaps || {};
  App.ActiveMaps[mapId] = map;
}
