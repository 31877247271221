import React from "react";
import opioidCrisisTease from "../../images/topics/drug_overdose_deaths.jpg";
import suicideTease from "../../images/stories/suicide/title.jpg";

export const getStoryLink = (key) => storyLinks[key];

const StoryCard = ({ href, title = "", image = null, teaser = null }) => (
  <section className="l-homepage">
    <div
      className="card border-0 mb-4 mt-1"
      style={{
        background: "#e5ebf2",
        overflow: "hidden",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px"
      }}
    >
      <div className="row">
        <div className="col-md-7 col-lg-6 d-flex flex-fill">
          <div className="card-body d-flex flex-fill align-items-center">
            <div>
              {title && <div className="h4 text-dark"> {title} </div>}
              <p className="card-description">{teaser}</p>
              <a className="rounded-pill btn btn-primary fw-bold" href={href}>
                Read the story
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-5 col-lg-6 d-flex">
          {image && (
            <figure className="c-image" style={{ maxHeight: "100%", height: "250px" }}>
              <img
                alt={image.alt}
                style={{ objectFit: "cover", minWidth: "100%", height: "100%" }}
                className="c-image__img"
                src={image.src}
              />
            </figure>
          )}
        </div>
      </div>
    </div>
  </section>
);

const baseEndpoint = "/stories";
const storyLinks = {
  opioidCrisis: (
    <StoryCard
      title="Delaware's Opioid Crisis"
      image={{ src: opioidCrisisTease, alt: "" }}
      href={`${baseEndpoint}/opioid-crisis`}
      teaser="In 2017, almost every day, a Delawarean died from a drug overdose."
    />
  ),
  suicide: (
    <StoryCard
      title="Suicide in Delaware"
      image={{ src: suicideTease, alt: "" }}
      href={`${baseEndpoint}/suicide`}
      teaser="Nearly 600 Delawareans died by suicide between 2014 and 2018."
    />
  )
};
