import React, { Component } from "react";
import { sendGAEvent } from "../utils/google_analytics";

export default class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.levels[0].flag
    };
  }

  componentWillReceiveProps() {
    this.setState({
      selectedTab: this.props.levels[0].flag
    });
  }

  handleTabClick(level, event) {
    this.setState({ selectedTab: level });
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: "Water",
      action: "Levels",
      label: `${this.props.contaminant}: ${level}`
    }});
  }

  levelsUi() {
    const levels = this.props.levels;
    if (!levels) return null;
    const tabs = [];
    let content = null;
    levels.forEach((level, i) => {
      const flag = level.flag;
      const isSelectedTab = this.state.selectedTab === flag;
      const selected = isSelectedTab ? "active" : "";
      tabs.push(
        <li className={"c-tab c-tab--" + flag}>
          <a
            className={`c-tab__link nav-link ${selected}`}
            href={`#${flag}`}
            onClick={this.handleTabClick.bind(this, level.flag)}
          >
            <span>{level.name}</span>
            <span>{level.context}</span>
          </a>
        </li>
      );
      if (isSelectedTab) {
        content = (
          <div className={`tab-content c-tabcontent c-tabcontent--${level.flag}`}>
            <div className="o-container--constrained">{level.description()}</div>
          </div>
        );
      }
    });
    return {
      tabs: tabs,
      content: content
    };
  }

  render() {
    const { selectedTab } = this.state;
    return (
      <div>
        <h3 className="mb-3">Levels & Recommended Actions</h3>
        <nav>
          <ul className={"nav nav-tab c-tabs--levels mb-0 " + selectedTab}>
            {this.levelsUi().tabs}
          </ul>
        </nav>
        {this.levelsUi().content}
      </div>
    );
  }
}
