import React from "react";
import { createRoot } from "react-dom/client";
import LocationJumper from "./LocationJumper";

export default class LocationJump {
  constructor(props) {
    const root = createRoot(document.querySelector(props.el || "#j-location-jumper"));
    root.render(<LocationJumper {...props} />);
  }
}
