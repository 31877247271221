import React, { Component } from "react";
import LoadingAnimation from "./LoadingAnimation";
import debounce from "lodash/debounce";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { fetch } from "whatwg-fetch";

export default class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.LOCAL_STORAGE_KEY = "recent_searches";
    this.RECENTS_LIMIT = 5;
    this.state = {
      results: null,
      searching: false,
      query: ""
    };
  }

  /**
   * handleSearch - Respond to input - fetch locations matching query and
   *                update state with results
   *
   * @param  {type} event Mouse event
   * @return NA
   */
  handleSearch(event) {
    event.preventDefault();
    const { value } = event.target;
    if (value.length === 0) {
      this.setState({ results: null, searching: false });
      return;
    }
    if (value.length > 0 && value.length <= 2) {
      return;
    }
    this.setState({ searching: true });
    fetch(`/search.json?query=${value}`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ results: data, searching: false, query: value });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({ results: [], searching: false, query: value });
      });
  }

  /**
   * handleSearchSelection - Handler for search selection
   *
   * @param  {Object} selection Name and id(slug) of selection
   * @param  {Object} event     Mouse event
   * @return NA
   */
  handleSearchSelection(selection, event) {
    event.preventDefault();
    const _selection = {
      name: selection.human_name || selection.name,
      slug: selection.slug,
      id: selection.slug
    };
    this.storeRecentSearch(_selection);
    this.props.handleUpdateSelectedItem(_selection, null);
  }

  /**
   * results - Returns formated nodes for results from search
   *
   * @return {Nodes}
   */
  results() {
    if (!this.state.results.length) {
      return <p className="mx-2">We're having trouble finding "{this.state.query}".</p>;
    }
    return this.formatResultsInline(this.state.results);
  }

  /**
   * formatResults - Create Nodes for each result
   *
   * @param  {Array} results=[]  Array of result objects
   * @return {Nodes}
   */
  formatResults(results = []) {
    return compact(
      results.map((r, i) => {
        if (isEmpty(r)) return null;
        return (
          <li key={`result-${i}`}>
            <a
              className="c-dropdown__item"
              onClick={this.handleSearchSelection.bind(this, r)}
              href="#"
            >
              {r.human_name || r.name}
            </a>
          </li>
        );
      })
    );
  }

  formatResultsInline(results = []) {
    const items = results.map((r, idx) => {
      if (isEmpty(r)) return null;
      return (
        <span key={`item-${i}`}>
          <a onClick={this.handleSearchSelection.bind(this, r)} href="#">
            {r.human_name || r.name}
          </a>
        </span>
      );
    });
    return compact(items);
  }

  /**
   * storeRecentSearch - Store select search item in local storage
   *                     only if not currently in array
   *
   * @param  {Object} selection Selected search item
   * @return NA
   */
  storeRecentSearch(selection) {
    if (!localStorage) return;
    const recents = this.getRecentSearches();
    if (!recents.find((r) => r.id === selection.id)) {
      recents.unshift(selection);
    }
    localStorage.setItem(
      this.LOCAL_STORAGE_KEY,
      JSON.stringify(recents.slice(0, this.RECENTS_LIMIT))
    );
  }

  /**
   * getRecentSearches - Get recent searches from local storage
   *
   * @return {Array}
   */
  getRecentSearches() {
    if (!localStorage) return [];
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY)) || [];
  }

  render() {
    const { location } = this.props;
    const { results, searching } = this.state;
    const { nearby } = location;
    const state_and_counties = [
      {
        human_name: "Delaware",
        slug: "state",
        type: "Location"
      },
      {
        human_name: "New Castle County",
        slug: "county-new-castle",
        type: "Location"
      },
      {
        human_name: "Kent County",
        slug: "county-kent",
        type: "Location"
      },
      {
        human_name: "Sussex County",
        slug: "county-sussex",
        type: "Location"
      }
    ];
    return (
      <div className="c-search">
        <div className="c-search__input-wrapper mx-2">
          <h4 className="c-search__input-label"> Search locations </h4>
          <input
            onInput={debounce(this.handleSearch.bind(this), 500)}
            className="c-search__input form-control"
            title="Enter a location to compare against"
          />
        </div>
        {(searching || results) && (
          <div className="c-search__results-wrapper" aria-label="Search Results">
            {searching && (
              <LoadingAnimation
                size={30}
                veil={true}
                padded={false}
                fontSize={11}
                labelComponent={<p>Searching</p>}
              />
            )}
            {results ? <ul className="c-search__results">{this.results()}</ul> : null}
          </div>
        )}
        {this.getRecentSearches().length && (
          <div>
            <h4 className="c-dropdown__section-title"> Recent searches </h4>
            <div className="c-dropdown__section">
              <div> {this.formatResultsInline(this.getRecentSearches())} </div>
            </div>
          </div>
        )}
        <div>
          <h4 className="c-dropdown__section-title">Top Level Pages</h4>
          <div className="c-dropdown__section">
            <div>{this.formatResultsInline(state_and_counties)}</div>
          </div>
        </div>
        {nearby && nearby.length && (
          <div>
            <h4 className="c-dropdown__section-title">Within {location.human_name}</h4>
            {nearby.map((section, i) => (
              <div className="mb-4" key={`result-section-${i}`}>
                <div className="c-dropdown__section-sub-title">{section.heading}</div>
                <div
                  className={`c-dropdown__section c-dropdown__section--${section.heading
                    .toLowerCase()
                    .replace(/\s/g, "-")}`}
                >
                  <div>{this.formatResultsInline(section.results)}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
