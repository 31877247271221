import React from "react";
import { createRoot } from "react-dom/client";
import TopicComparisonLegend from "./TopicComparisonLegend";

export default class {
  constructor(props) {
    const root = createRoot(document.querySelector(props.el || "#comparison-legend"));
    root.render(<TopicComparisonLegend {...props} />);
  }
}
