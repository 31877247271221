import Base from "./base";
import { colors } from "./options";
import { donut } from "billboard.js";

export default class extends Base {
  getColors() {
    const options = this.props.options;
    if (options.colors) {
      return options.colors;
    } else if (options.binary) {
      return [colors()[0], "#CCCCCC"];
    }
    return colors();
  }

  options() {
    const opts = this.props.options;
    const _options = {
      data: {
        columns: this.props.data,
        type: donut(),
        order: opts.order || null
      },
      donut: {
        title: "",
        label: {
          show: false
        }
      },
      color: {
        pattern: this.getColors()
      },
      size: {
        height: "size" in opts && opts.size.height ? opts.size.height : 85,
        width: "size" in opts && opts.size.width ? opts.size.width : 85
      },
      padding: { top: 0, bottom: 0, left: 0, right: 0 },
      legend: {
        show: false
      },
      tooltip: {
        show: "showTooltip" in opts ? opts.showTooltip : false
      },
      bindto: this.props.el
    };
    if ("preventMouseEvents" in opts) {
      _options.onrendered = function () {
        this.main$.chart
          .selectAll(".bb-chart-arcs path")
          .attr("style", "pointer-events: none")
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null);
      };
    }
    if ("padding" in opts) {
      _options.padding = opts.padding;
    }
    return _options;
  }
}
