import React, { useCallback, useState, useMemo } from "react";
import TopicContext from "../topic/TopicContext";
import Actions from "./Actions";
import getLevelActions from "./level_actions";
import {
  Alert,
  CssBaseline,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ThemeProvider,
} from "@mui/material";
import { theme } from "../../components/theme/";

const DetailedContaminants = ({ contaminants=[], type, data }) => {
  const [selectedContaminant, setSelectedContaminant] = useState(
    contaminants[0],
  );

  const handleContaminantSelection = useCallback(
    (event) => {
      setSelectedContaminant(
        contaminants.find((c) => c[0].slug == event.target.value),
      );
    },
    [contaminants],
  );

  const contaminantLevels = useMemo(() => {
    return getLevelActions(selectedContaminant.name);
  }, [selectedContaminant]);

  if (!data || !data.chart.sets || !data.chart.sets.length) {
    return <Alert severity="error">No data found for this chart</Alert>;
  }

  const currentChartSets = data.chart.sets.find(
    (s) => s.slug === selectedContaminant[0].slug,
  );

  const topicContextProps = {
    data: {
      chart: {
        sets: [currentChartSets],
        title: selectedContaminant[0].name,
        granularity: data.chart.granularity,
        titleAddendum: data.chart.titleAddendum,
        relatedDataText: "Number of wells tested",
        options: {
          defaultAxisMin: 0,
        },
      },
      about: data.about || {},
      startYear: data.startYear,
      endYear: data.startYear,
      animations: data.animations,
      resources: data.resources,
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <div className="c-topic-header">
          <div className="c-topic-header__selection">
            <Stack gap={1}>
              <InputLabel id="contaminant-picker-label" htmlFor="contaminant-picker">Select Contaminant</InputLabel>
              <Select
                labelId="contaminant-picker-label"
                id="contaminant-picker"
                value={selectedContaminant[0].slug}
                onChange={handleContaminantSelection}
                inputProps={{
                  sx: {
                    background: "white",
                    minWidth: 300,
                  },
                }}
              >
                {contaminants.map(([{ name, slug }]) => (
                  <MenuItem key={slug} value={slug}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </div>
        </div>
        <article className="o-topic-detail-container mb-6">
          <h4>{selectedContaminant[0].name}</h4>
          <div className="o-topic__section o-topic__section--secondary p-0">
            <div
              className="o-container--constrained"
              dangerouslySetInnerHTML={{
                __html: selectedContaminant[0].description,
              }}
            />
          </div>
          {type === "private" && contaminantLevels ? (
            <Actions
              levels={contaminantLevels}
              contaminant={selectedContaminant[0].name}
            />
          ) : null}
          <TopicContext {...topicContextProps} />
        </article>
      </div>
    </ThemeProvider>
  );
};

export default DetailedContaminants;
