import React from "react";
import { createRoot } from "react-dom/client";
import MetricCards from "./MetricCards";

export default class metricCards {
  constructor(props) {
    const root = createRoot(document.querySelector(props.el || "#slats"));
    root.render(<MetricCards {...props} />);
  }
}
