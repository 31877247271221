import React from "react";
import { createRoot } from "react-dom/client";
import TopicComparisonSelection from "./TopicComparisonSelection";

export default class Context {
  constructor(props) {
    const root = createRoot(document.querySelector(props.el || "#topic-comparison"));
    root.render(<TopicComparisonSelection {...props} />);
    render();
  }
}
