var placesService, inputEl, resultsEl;

export default function Typeahead() {
  const typeaheadEls = document.getElementsByClassName('j-typeahead')

  for (var i = typeaheadEls.length - 1; i >= 0; i--) {
    initializeTypeahead(typeaheadEls[i])
  }
}

function triggerTypeahead(e) {
  const query = e.target.value

  resultsEl.innerHTML = wrap_list_item('Loading suggestions...', {'disabled': true})

  if (query !== '') {
    resultsEl.style.display = 'block'
    getPlacePredictions(query)
  } else {
    resultsEl.style.display = 'none'
  }
}

function handleKeydown(e) {
  let currentListItem = e.target.closest('li.c-list__item.clickable')
  let nextListItem = null

  if (currentListItem) {
    if (e.keyCode == 40) {
      // Down arrow key
      nextListItem = currentListItem.nextSibling
    } else if (e.keyCode == 38) {
      // Up arrow key
      nextListItem = currentListItem.previousSibling
    } else {
      inputEl.focus()
    }
  } else {
    if (e.keyCode == 40) {
      // Down arrow key
      nextListItem = document.querySelector('li.c-list__item.clickable')
    }
  }

  if (nextListItem) {
    const nextLink = nextListItem.querySelector('a.c-list__link')
    nextLink.focus()
    inputEl.value = nextLink.textContent
    e.preventDefault()
  }
}

function initializeTypeahead(typeaheadEl) {
  inputEl = typeaheadEl.querySelector('input')
  resultsEl = document.createElement('div')
  resultsEl.className = 'c-search-results__container o-search-list__results'
  typeaheadEl.appendChild(resultsEl)

  inputEl.addEventListener('input', triggerTypeahead);
  inputEl.addEventListener('focus', triggerTypeahead);
  inputEl.addEventListener('keydown', handleKeydown);

  // Hide results when click occurs out of the results and inputs
  google.maps.event.addDomListener(document, 'click', function(e) {
    if (!e.target.parentElement) { return }

    if ((e.target.parentElement.className !== 'c-search-results__container') &&
        (e.target.parentElement.className !== 'c-list__item') &&
        (e.target.tagName !== 'INPUT')) {
      resultsEl.style.display = 'none';
    }
  });
}

// Get place predictions
function getPlacePredictions(query) {
  if (query.length > 2) {
    fetch('/search?format=json&query=' + query)
      .then(response => response.json())
      .then(placeSearchCallback)
      .catch(() => { placeSearchCallback(null) })
    } else {
      placeSearchCallback(null)
    }
}

// Wrap content to look like a list item
function wrap_list_item(content, options = {}) {
  let classes = `c-list__item${options['disabled'] ? '' : ' clickable'}`
  if (options['extraClasses']) {
    classes += ` ${options['extraClasses']}`
  }
  return `<li class="${classes}">` +
    `<a href="${options['href'] || '#'}" class="c-list__link${options['disabled'] ? ' disabled' : ''}">` +
    content +
    '</a>' +
    '</li>'
}

// Wrap content to look like a list
function wrap_list(content, options = {}) {
  return `<ul class="c-list o-search-list__results-list">${content}</ul>`
}

// Place search callback
function placeSearchCallback(predictions) {
  // Empty results container
  resultsEl.innerHTML = ''

  if (predictions) {
    if (predictions.length > 0) {
      // Build output for each database prediction
      for (var i = 0, prediction; prediction = predictions[i]; i++) {
        // Insert output in results container
        const name = prediction.name || prediction.human_name
        resultsEl.innerHTML += wrap_list_item(name, {
          'href': `/search?query=${name}`,
        })
      }
    } else {
      const message = 'No matching local reports found.'
      resultsEl.innerHTML += wrap_list_item(message, {
        'extraClasses': 'c-search-results__item-error',
        'disabled': true,
      })
    }
  } else {
    const message = 'Please input more characters.'
    resultsEl.innerHTML += wrap_list_item(message, {'disabled': true})
  }

  resultsEl.innerHTML = wrap_list(resultsEl.innerHTML)

  var items = document.getElementsByClassName("c-list__item clickable");

  // Results items click
  for (var i = 0, item; item = items[i]; i++) {
    item.onclick = function() {
      inputEl.value = this.textContent;
      resultsEl.style.display = 'none';
    }
    item.onkeydown = handleKeydown
  }
}
