import React from "react";
import { createRoot } from "react-dom/client";
import Topic from "./Topic";

export default class Context {
  constructor(props) {
    const root = createRoot(document.querySelector(props.el || "#topic"));
    root.render(<Topic {...props} />);
  }
}
