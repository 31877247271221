// Only the superclass for the class in donut.js I think

import bb from "billboard.js";

export default class Base {
  constructor(props) {
    this.props = props;
    this.chart = null;
    this.props.options = props.options || {};
    this.render();
  }

  options() {
    return {};
  }

  render() {
    this.chart = bb.generate(this.options());
  }
}
