import React from "react";
import { createRoot } from "react-dom/client";
import LoadingAnimation from "./LoadingAnimation";

export default class pageLoading {
  constructor(props) {
    const root = createRoot(document.body);
    root.render(
      <LoadingAnimation
        veil={true}
        labelComponent={props.message || null}
        withBG={true}
        padded={true}
        positionFixed={true}
      />
    );
  }
}
