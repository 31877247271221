// import "core-js/stable";
// import "regenerator-runtime/runtime";
import $ from "expose-loader?exposes=$,jQuery!jquery"; // eslint-disable-line no-unused-vars
import "popper.js";
import "./bootstrap";
import "activestorage";
import Rails from "rails-ujs";
window.Rails = Rails;
Rails.start();

import "daterangepicker";

// Extensions - Add prototype methods to base JS Object Classes
import "../extensions";

// Establish App object and methods/functions
// stored as global
import "./api";

// Init JS for UI - tooltips and other events
import "./ui";

import "./jss";
