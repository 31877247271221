import React, { Component } from "react";
import LocationSearch from "../LocationSearch";
import PageLoading from "../page_loading";
import { sendGAEvent } from "../utils/google_analytics";

export default class LocationJumper extends Component {
  handleUpdateSelectedItem(selectedItem) {
    const locationRegExp = new RegExp(this.props.location.slug, "g");
    // show loading animation and add veil to page
    const { title } = this.props;
    const titleMessage = title ? `<strong>${title}</strong> for ` : "";
    new PageLoading({
      message: (
        <p className="text-center">
          {`Navigating to ${titleMessage}`}
          <strong style={{ fontSize: "16px", display: "block" }}>
            {selectedItem.human_name || selectedItem.name}
          </strong>
        </p>
      )
    });
    sendGAEvent({eventType: "user_interaction", parameters: {
      category: "Location picker",
      action: "Jump to location selection",
      label: selectedItem.name || selectedItem.slug
    }});
    window.top.location = window.location.href.replace(
      locationRegExp,
      selectedItem.slug || selectedItem.id
    );
  }

  render() {
    const { location, modalId } = this.props;
    return (
      <div id={modalId} className="modal fade" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h5 className="modal-title">Choose a location within Delaware</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                <span className="fas fa-times-circle" aria-hidden="true"></span>
              </button>
            </div>
            <div className="modal-body modal-body--restricted-height">
              <LocationSearch
                location={location}
                handleUpdateSelectedItem={this.handleUpdateSelectedItem.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
