import { mapFeatureColors } from './settings'

const CLICKABLE_POINTS = [
  'treatment-center',
  'treatment-provider',
  'monitoring-site',
  'naloxone',
]
const DEFAULT_GOOGLE_ICON_PATH = 'https://maps.google.com/mapfiles/kml/paddle/red-circle.png'
// feature: icon_name (svg symbol)
const CUSTOM_MAP_ICONS = {
  default: DEFAULT_GOOGLE_ICON_PATH,
}


/**
 * updateMapBounds - Called when UI changes - on tab switch or when a map is
 *                   revealed after being hidden, we need to update the bounds
 * @param  {Array} activeMaps Array of active maps currently on page
 * @return NA
 */
export function updateMapBounds (activeMaps=App.ActiveMaps) {
  if (activeMaps) {
    Object.keys(App.ActiveMaps).map(k => {
      activeMaps[k].data.forEach(f => setBoundsToFeature(f, activeMaps[k]))
    })
  } else {
    return false
  }
}

/**
 * setBoundsToFeature - Set bounds of map to extent of feature's polygon
 *                      but only if is of type location (to prevent zooming to
 *                      bounds of state masks
 *
 * @param  {Obj} feature
 * @return NA
 */
export function setBoundsToFeature (feature, map) {
  if (feature.getProperty('FEATURE_TYPE') == 'location') {
    const bounds = new google.maps.LatLngBounds()
    feature
      .getGeometry()
      .forEachLatLng(latlng => bounds.extend(latlng))
    map.fitBounds(bounds)
  } else {
    return
  }
}

/**
 * addMouseEvents - Add common mouse events to map
 *                  Some conditionally based on map data
 *
 * @param  {type} map Map instance
 * @return NA
 */
export function addMouseEvents(map, options=null) {
  const opts = Object.assign( { allowLocationPolygonClick: true }, options )
  map.data.addListener('mouseover', function(event) {
    const featureType = event.feature.getProperty('FEATURE_TYPE')

    if (featureType == 'location' && opts.allowLocationPolygonClick) {
      map.data.revertStyle()
      map.data.overrideStyle(event.feature, {
        fillColor: mapFeatureColors().highlight,
        strokeColor: mapFeatureColors().highlight,
        strokeWeight: 2,
        fillOpacity: 0.2
      })
    }
  })

  map.data.addListener('mouseout', function(event) {
    map.data.revertStyle();
  })

  map.data.addListener('click', function(event) {
    const feature = event.feature
    const featureType = feature.getProperty('FEATURE_TYPE')
    if (featureType == 'location' && opts.allowLocationPolygonClick) {
      window.top.location = event.feature.getProperty('PATH')
      return
    }
  })

  map.addListener('click', function(event) {
    if (opts.allowLocationPolygonClick) {
      window.top.location = `/search?lat=${event.latLng.lat()}&lng=${event.latLng.lng()}&zoom=${map.zoom}`
    }
  })
}

/**
 * addInfoWindow - Create instance of Google's InfoWindow Class with properties
 *
 * @param  {String} typeName    Name of feature type (for subtitle)
 * @param  {Object} properties  Properties of feature
 * @param  {Object} position    Geo Position (Google Position instance)
 * @return {Object}             Instance of InfoWindow
 */
export function addInfoWindow(typeName, properties, position) {
  const featureType = properties.FEATURE_TYPE
  if (CLICKABLE_POINTS.includes(featureType)) {
    const website = properties.WEBSITE
    const phones = properties.PHONES
    let websiteUi = ''
    if (website) {
      websiteUi = `
        <span class='c-info-window__item'>
          <i class='c-info-window__icon fa fa-external-link-alt'></i>
          <a href='${website}' target='_BLANK' class='c-info-window__link'>
            ${website}
          </a>
        </span>
      `
    }
    let phoneUi = ''
    if (phones && phones.length && phones[0] != 0) {
      phoneUi = `
        <span class='c-info-window__item'>
          <i class='c-info-window__icon fa fa-phone'></i>
          <ul class='c-info-window__list'>${phones.map(p => `<li>${p}</li>`).join('')}</ul>
        </span>
      `
    }
    return new google.maps.InfoWindow({
      content: `
        <div class='c-info-window'>
          <h1>${properties.NAME}</h1>
          <h2>${typeName}</h2>
          <div class='c-info-window__list'>${websiteUi}${phoneUi}</div>
        </div>
      `,
      position: position,
      pixelOffset: new google.maps.Size(0,0),
    })
  } else {
    return null
  }
}

/**
 * addMapPoints - Add points to map with custom icons and info windows
 *
 * @param  {Object} pointData       Point data including position and properties
 * @param  {Object} map             Google map instance
 * @param  {String} icon=null       Icon path
 * @param  {Array}  mapInfoWindows  Collection of all info windows of map
 * @param  {Array}  mapMarkers      Collection of all markers of map
 * @return NA
 */
export function addMapPoints(pointData, map, icon=null, mapInfoWindows, mapMarkers) {
  pointData.features.forEach(feature => {
    if (!feature || !feature.geometry) return
    const { properties, geometry } = feature
    const [ lng, lat ] = geometry.coordinates
    const position = new google.maps.LatLng(lat, lng)
    const iconPath = icon || properties.ICON || mapIcon('default')
    const _icon = {
      url: iconPath,
      scaledSize: new google.maps.Size(64, 64),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(32, 64)
    }
    const marker = new google.maps.Marker({
      position: position,
      map: map,
      icon: _icon,
    })
    mapMarkers.push(marker)
    const infoWindow = addInfoWindow(feature.name || '', properties, position)
    mapInfoWindows.push(infoWindow)
    google.maps.event.addListener(marker, 'click', () => {
      closeAllInfoWindows(mapInfoWindows)
      infoWindow.open(map, marker)
    });
  })
}

/**
 * closeAllInfoWindows - Call close method of InfowWindow in array
 *
 * @param  {Array} infoWindows Collection of InfowWindow instances
 * @return NA
 */
export function closeAllInfoWindows(infoWindows) {
  infoWindows.forEach(w => {
    w.close()
  })
}

/**
 * mapIcon - description
 *
 * @param  {String} iconName Name of icon to get
 * @return {String}          Name of icon
 */
export function mapIcon(iconName) {
  return CUSTOM_MAP_ICONS[iconName]
}

/**
 * styleSearchResults - description
 *
 * @param  {Object} feature The feature to style
 * @return {Object}         The styles to be applied to that feature
 */
export function styleSearchResults(feature) {
  // Color based on feature type
  const featureColors = mapFeatureColors()
  const featureType = feature.getProperty('FEATURE_TYPE')
  const color = featureColors[featureType] || featureColors.default

  // Only show primary location to start
  const isPrimaryLocation = feature.getProperty('PRIMARY_LOCATION')
  const visible = featureType != 'location' || isPrimaryLocation

  return {
    fillColor: color,
    strokeColor: color,
    strokeWeight: 2,
    fillOpacity: 0.25,
    visible: visible,
  }
}

/**
 * styleNationalChoropleth - description
 *
 * @param  {Object} feature The feature to style
 * @return {Object}         The styles to be applied to that feature
 */
export function styleNationalChoropleth(feature) {
  const ramps = [
    [
      '#f1eef6',
      '#d4b9da',
      '#c994c7',
      '#df65b0',
      '#dd1c77',
      '#980043',
    ],
    [
      '#f2f0f7',
      '#cbc9e2',
      '#9e9ac8',
      '#6a51a3',
    ],
    [
      '#f7f4f9',
      '#e7e1ef',
      '#d4b9da',
      '#c994c7',
      '#df65b0',
      '#e7298a',
      '#ce1256',
      '#91003f',
    ]
  ]

  function getColorWideRange(d, _colorRampIndex) {
    return d > 21 ? ramps[_colorRampIndex][5] :
           d > 18 ? ramps[_colorRampIndex][4] :
           d > 16 ? ramps[_colorRampIndex][3] :
           d > 13 ? ramps[_colorRampIndex][2] :
           d > 11 ? ramps[_colorRampIndex][2] :
                    ramps[_colorRampIndex][0]
  }
  function getColorShallowRange(d, _colorRampIndex) {
    return d > 107.1 ? ramps[_colorRampIndex][3] :
           d > 82.9 ? ramps[_colorRampIndex][2] :
           d > 64.1 ? ramps[_colorRampIndex][1] :
                      ramps[_colorRampIndex][0]
  }

  let colorRampIndex = 0
  let colorFn = getColorWideRange
  switch (feature.getProperty('dataSetName')) {
    case 'overdose-death-rate':
      break
    case 'prescription-rate':
      colorRampIndex = 1
      colorFn = getColorShallowRange
      break
  }

  // Color based on state value
  const color = colorFn.call(
    null,
    feature.getProperty('density'),
    colorRampIndex
  )

  return {
    fillColor: color,
    strokeColor: 'white',
    strokeWeight: 2,
    fillOpacity: 0.7,
  }
}
