import React, { Component } from "react";

export default class TopicUtility extends Component {
  handleToggleComparisonUI(event) {
    event.preventDefault();
    const target = event.target;
    const comparisonContainer = document.querySelector(".c-comparison-header");
    if (comparisonContainer) {
      const classList = comparisonContainer.classList;
      let classActionMethod = "add";
      if (classList.contains("show")) classActionMethod = "remove";
      target.classList[classActionMethod]("open");
      classList[classActionMethod]("show");
    }
  }

  render() {
    const { location } = this.props;
    return (
      <div className="c-topic-mobile-utility">
        <h1 className="c-topic-mobile-utility__title"> {location} </h1>
        <nav className="c-topic-mobile-utility__nav">
          <button className="btn btn-link j-nav-toggle c-topic-mobile-utility__action">
            <i className="fas fa-bars mr-2" />
            Topics
          </button>
          <div className="c-topic-mobile-utility__action c-topic-mobile-utility__action--right">
            <a onClick={this.handleToggleComparisonUI.bind(this)} href="#">
              Compare
              <i className="fas fa-angle-down" />
            </a>
          </div>
        </nav>
      </div>
    );
  }
}
