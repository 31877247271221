// Area chart
// Very limited functionality currently — basically only renders 1
// set of data

import bb, { area } from "billboard.js";

export default class Area {
  constructor(domSelector, data, options = {}) {
    this.data = data;
    this.domSelector = domSelector;
    this.options = options;
    this.render();
  }

  buildOptions() {
    return {
      size: {
        width: document.querySelector(this.domSelector).clientWidth
      },
      data: {
        columns: [["data1", ...this.data]],
        types: {
          data1: area()
        },
        colors: {
          data1: this.options.color
        }
      },
      axis: {
        x: {
          show: false,
          padding: 0
        },
        y: {
          show: false,
          padding: 0,
          max: this.options.max,
          min: this.options.min
        }
      },
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      point: { show: false },
      legend: { show: false },
      tooltip: {
        show: this.options.tooltip
      },
      bindto: this.domSelector
    };
  }

  render() {
    this.chart = bb.generate(this.buildOptions());
  }
}
