export function mapColors() {
  return [
    '#147BB1',
    '#E3733B',
  ]
}

export function mapFeatureColors() {
  return {
    default: '#FFFFFF',
    mask: '#817e7a',
    location: mapColors()[0],
    highlight: mapColors()[1],
  }
}


export function baseMapStyles() {
  return [
    {
      "featureType": "landscape",
      "stylers": [
        {
          "color": '#FFFFFF',
        }
      ]
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "color": "#e8efe8"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "color": "#E6E4E1"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "color": "#ffffff"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#d2d7e1"
        },
      ]
    }
  ]
}
