import React from "react";
import classnames from "classnames";
import { getStoryLink } from "../story/links";

const TopicTitle = (props) => {
  const { title, topicIntroStoryKey, bordered } = props;
  const textClassNames = classnames("c-topic-header__text", {
    "c-topic-header__text--bordered": bordered
  });
  return (
    <div className="c-topic-header c-topic-header--nowrap">
      <div className={textClassNames}>
        <div className="d-flex justify-content-between flex-wrap">
          <h1 className="c-topic-header__heading mb-0"> {title} </h1>
        </div>
      </div>
      {topicIntroStoryKey && getStoryLink(topicIntroStoryKey)}
    </div>
  );
};

export default TopicTitle;
