import React, { Component } from "react";
import classnames from "classnames";

export default class extends Component {
  constructor(props) {
    super(props);
    this.options = Object.assign(
      {
        showTrendLine: true,
        showSwatch: false,
        showChange: true,
        verticalPadding: true,
        secondaryAppearance: false
      },
      props.options
    );
  }

  render() {
    const { primaryLocation, comparisonLocation, isStatic, staticItemName } = this.props;
    let comparisonLocationName = "";
    if (comparisonLocation)
      comparisonLocationName = comparisonLocation.name || comparisonLocation.human_name;
    if (isStatic) {
      comparisonLocationName = staticItemName;
    }
    // Need to better handle case when primary and comparison location is
    // same when generating data - we currently have no way of knowing at the
    // data render level
    if (!comparisonLocation || comparisonLocation.slug === primaryLocation.slug) {
      comparisonLocationName = "";
    }

    const mainClassNames = classnames("c-comparison-legend", {
      "c-comparison-legend--secondary": this.options.secondaryAppearance
    });

    const groupClassNames = classnames("c-comparison-legend__group", {
      "c-comparison-legend__group--vertical-padding": this.options.verticalPadding
    });

    return (
      <div className={mainClassNames}>
        <div className={groupClassNames} aria-label="Legend for summary metrics">
          {this.options.showTrendLine && (
            <svg className="c-comparison-legend__line">
              <g fill="none" strokeLinecap="round" strokeWidth="1" stroke="none">
                <path
                  className="c-comparison-legend__spark-line--primary"
                  d="M0,17 C1.8385021,12.7934239 4.93770801,10.6615412 9.29761775,10.604352 C14.8412819,10.5316355 14.1494487,14.9787312 19.5661397,15.1921748 C23.1772671,15.3344705 25.9885538,13.6037456 28,10"
                ></path>
              </g>
            </svg>
          )}
          {this.options.showSwatch && <span className="c-swatch c-swatch--primary" />}
          <span className="c-comparison-legend__item-label">{primaryLocation.human_name}</span>
        </div>
        {comparisonLocationName && (
          <div className={groupClassNames}>
            {this.options.showTrendLine ? (
              <svg
                className="c-comparison-legend__line c-comparison-legend__line--comparison"
                alt=""
              >
                <g fill="none" strokeLinecap="round" strokeWidth="1" stroke="none">
                  <path d="M0,17 C1.8385021,12.7934239 4.93770801,10.6615412 9.29761775,10.604352 C14.8412819,10.5316355 14.1494487,14.9787312 19.5661397,15.1921748 C23.1772671,15.3344705 25.9885538,13.6037456 28,10"></path>
                </g>
              </svg>
            ) : null}
            {this.options.showSwatch ? (
              <span className="c-swatch c-swatch--comparison" />
            ) : (
              <span
                className="c-metric-value c-metric-value--comparison mr-2"
                aria-label="Example of Primary geographic context"
              >
                &nbsp;&nbsp;
              </span>
            )}

            <span className="c-comparison-legend__item-label">{comparisonLocationName}</span>
          </div>
        )}
        {this.options.showChange && (
          <div
            className={groupClassNames}
            aria-label="Summary metrics include description of direction of change in the metric over time"
          >
            <div className="o-swatch mr-4">
              <div className="c-swatch c-swatch--positive"></div>
              <span className="c-comparison-legend__item-label">Positive</span>
            </div>
            <div className="o-swatch">
              <div className="c-swatch c-swatch--negative"></div>
              <span className="c-comparison-legend__item-label">Negative</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}
