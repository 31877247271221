import React from "react";
import { createRoot } from "react-dom/client";
import DetailedContaminants from "./DetailedContaminants";

export default class {
  constructor(props) {
    this.props = props;
    this.props.dateFormat = props.dateFormat || "year";
    const root = createRoot(document.querySelector(props.el));
    root.render(<DetailedContaminants {...this.props} />);
  }
}
