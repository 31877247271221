// Snapshot taken: June 1, 2023
// Most custom colors are maintained in "src/app/styles/colors.ts"

import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles";
import {
  brandPalette,
  geographyColors,
  DemographicKeys,
  demographicColors,
  Color,
  additionalPalette,
  linkColor
} from "./colors";

const fontFamily = "Open Sans, sans-serif";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface Palette {
    brand: Palette["primary"] & { contrastBlue: string };
    brandSecondary: Palette["primary"];
    borders: {
      light: string;
      main: string;
      dark: string;
    };
    dropdowns: Palette["primary"];
    nav: Palette["primary"];
    light: Record<"secondary" | "primary", string>;
    dark: Record<string, string>;
    component: Record<string, string>;
    demographics: Record<DemographicKeys, Record<string, string>>;
    geography: Color;
  }
  interface PaletteOptions {
    brand: PaletteOptions["primary"] & { contrastBlue: string };
    brandSecondary: PaletteOptions["primary"];
    borders: Record<string, string>;
    nav: PaletteOptions["primary"];
    dropdowns: PaletteOptions["primary"];
    light: Record<"secondary" | "primary", string>;
    dark: Record<string, string>;
    component: Record<string, string>;
    demographics: Record<DemographicKeys, Record<string, string>>;
    geography: Color;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    brand: true;
    dark: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    brand: true;
    dark: true;
  }
}

const { brand, brandSecondary, success } = brandPalette;
const { age, race, ethnicity, sex, grade } = demographicColors;
const { component } = additionalPalette;

// Create a theme instance.
const altTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          flex: "auto",
          "> #__next": {
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto"
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.variant === "outlined" && { borderWidth: 2 }),
          ...(ownerState?.severity === "success" && {
            "& .MuiAlert-icon": { color: "rgb(89, 151, 91)" }
          }),
          fontWeight: 600
        }),
        standardInfo: {
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288D1;",
          border: "2px solid #245194"
        },
        standardSuccess: {
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2E7D32;",
          border: "2px solid #2E7D32"
        },
        standardWarning: {
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ED6C02;",
          border: "2px solid #ED6C02"
        },
        standardError: {
          background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F;",
          border: "2px solid #D32F2F"
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: "2px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          marginBottom: 3,
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "24px",
          color: "rgba(0, 0, 0, 0.87)"
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: "black",
          "&:hover": {
            "> *": {
              color: linkColor,
              fill: linkColor
            }
          },
          "&.Mui-active": {
            "> *": {
              color: "#0060F0",
              fill: "#0060F0"
            },
            "&:hover": {
              "> *": {
                color: "#0060F0",
                fill: "#0060F0"
              }
            }
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.hover && {
            cursor: "pointer",
            transition: "background-color 300ms linear",
            "&:hover": {
              backgroundColor: "transparent !important",
              td: { backgroundColor: alpha(brand.main, 0.05) }
            }
          })
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          lineHeight: "18px",
          padding: "12px 10px"
        }
      }
    }
  },
  typography: {
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightLight: 300,
    fontFamily,
    h1: {
      fontSize: 38,
      fontWeight: 300,
      lineHeight: 1.2
    },
    h2: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.125
    },
    h3: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.36
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.334
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.38888889
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.3
    },
    body1: {
      fontSize: 16,
      lineHeight: "26px",
      fontFamily
    },
    body2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontFamily
    },
    body3: {
      fontSize: "12px",
      lineHeight: "16px",
      fontFamily
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
      fontFamily
    }
  },
  palette: {
    action: {
      disabledBackground: "rgba(0, 0, 0, .04)"
    },
    borders: {
      light: "#E5E5E5",
      main: "rgba(0, 0, 0, 0.23)",
      dark: "rgba(0, 0, 0, 0.5)"
    },
    brand: {
      ...brand,
      contrastText: "#fff",
      contrastBlue: "#2669aa"
    },
    brandSecondary,
    component,
    dark: {
      main: "#616161" // grey["700"]
    },
    demographics: {
      race,
      ethnicity,
      age,
      sex,
      grade
    },
    dropdowns: {
      main: linkColor
    },
    geography: geographyColors,
    light: {
      secondary: "rgb(0,0,0,0.54)",
      primary: "rgb(0,0,0,0.87)"
    },
    nav: {
      main: brand.main,
      light: "#495057"
    },
    primary: {
      //main: "#1576d7"
      main: "#0260f0"
    },
    secondary: {
      ...brand
    },
    success,
    warning: {
      main: "#FF9800"
    }
  }
});

export const theme = responsiveFontSizes(altTheme);
