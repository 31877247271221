import React, { Component } from "react";
import TopicComparisonSelection from "./TopicComparisonSelection";
import TopicUtility from "./TopicUtility";
import TopicTitle from "./TopicTitle";
import classnames from "classnames";

export default class TopicHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { comparisonLocation: this.props.comparisonLocation };
  }

  handleComparisonChange(selectedItem) {
    this.setState({ comparisonLocation: selectedItem });
    if (this.props.callback) {
      this.props.callback(selectedItem);
    } else if (this.props.handleComparisonChange) {
      this.props.handleComparisonChange(selectedItem);
    }
  }

  render() {
    const {
      title,
      topicIntroStoryKey,
      bordered,
      items,
      location,
      primaryLocation,
      comparisonLocation,
      description,
      showUtilityNav = false
    } = this.props;
    const primaryLocationName = location || primaryLocation.human_name;

    const classNames = classnames("o-topic__header");

    return (
      <div className={classNames}>
        <TopicUtility location={primaryLocationName} />
        <TopicTitle
          title={title}
          location={primaryLocation}
          bordered={bordered}
          topicIntroStoryKey={topicIntroStoryKey}
          showUtilityNav={showUtilityNav}
        />
        {items && (
          <div
            className="c-comparison-header j-comparison-header"
            aria-label="Summary metrics comparison picker and legend"
          >
            {description && (
              <div
                className="c-comparison-header__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            <span className="c-comparison-header__text">Comparing</span>
            <span className="c-comparison-header__text c-comparison-header__text--primary">
              {primaryLocationName}
            </span>
            <span className="c-comparison-header__text">with</span>
            <TopicComparisonSelection
              items={items}
              primaryLocation={primaryLocation}
              selectedItemId={comparisonLocation ? comparisonLocation.slug : null}
              changeHandler={this.handleComparisonChange.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}
