// Snapshot taken: June 1, 2023

export type DemographicKeys = "race" | "ethnicity" | "age" | "sex" | "grade";
export type GeographyKeys = "benchmark" | "selected" | "comparison";
type BrandKeys = "brand" | "brandSecondary" | "success";
type MUIColorPaletteOptions = "light" | "main" | "dark";
type MUIColorPalette = Record<MUIColorPaletteOptions, Required<string>>;
export type MUIColors = Record<BrandKeys, MUIColorPalette>;
export type Colors = Record<string, Required<Color>>;
export type Color = Record<string, Required<string>>;
type AdditionalColors = Record<string, string>;

export const brandPalette: MUIColors = {
  brand: {
    light: "#F0F3F8",
    main: "#245194",
    dark: "#1F334F"
  },
  brandSecondary: {
    light: "#FFF9F8",
    main: "#942C1B",
    dark: "#3E120B"
  },
  success: {
    light: "#F1FBF2",
    main: "#307D33",
    dark: "#116615",
  }
};

export const linkColor = "#0060F0";

export const additionalPalette: Record<"component", AdditionalColors> = {
  component: {
    background: "#FAFAFA",
    links: linkColor
  }
};

export const geographyColors: Color = {
  benchmark: "#BDBDBD",
  selected: "#1E90FF",
  comparison: "#FFA51E"
};

export const demographicColors: Record<DemographicKeys, Required<Color>> = {
  race: {
    white: "#336770",
    black: "#E6B70F",
    asian: "#1F77B4",
    multiracial: "#8BC695",
    otherRace: "#B4AFFF",
    nativeAmerican: "#A0DEE7",
    pacificIslander: "#F7C390"
  },
  ethnicity: {
    nonHispanic: "#CF9C5B",
    hispanic: "#793F88"
  },
  sex: {
    male: "#0D3C61",
    female: "#8B84FF"
  },
  grade: {
    eight: "#BE3723",
    eleven: "#671E13"
  },
  age: {
    1: "#FDE725",
    2: "#C8E020",
    3: "#90D743",
    4: "#5EC962",
    5: "#35B779",
    6: "#20A486",
    7: "#21918C",
    8: "#287C8E",
    9: "#31688E",
    10: "#3B528B",
    11: "#440154",
    12: "#481F70",
    13: "#443983"
  }
};

export const colorDescriptions: Record<string, string> = {
  brand: "Blue",
  brandSecondary: "Maroon",
  success: "Green",
  race: "Multiracial to be used in all instances of “Multi racial, Two + Races, etc.",
  ethnicity: "Non-Hispanic is the correct way to label this ethnicity",
  age: "Age groups are a special case that is representing using the Viridis color palette."
};
