import "./namespace";

import Map from "../maps/map";
App.Map = Map;

// Topic
App.Topic = App.Topic || {};
import TopicPage from "../topic/index";
App.Topic.Page = TopicPage;
import metricCards from "../MetricCard/metric_cards";
App.Topic.MetricCards = metricCards;
import TopicContext from "../topic/context";
App.Topic.Context = TopicContext;
import TopicComparisonSelection from "../topic/topic_comparison_selection";
App.Topic.ComparisonSelection = TopicComparisonSelection;
import TopicHeader from "../topic/topic_header";
App.Topic.Header = TopicHeader;
import TopicComparisonLegend from "../topic/topic_comparison_legend";
App.Topic.ComparisonLegend = TopicComparisonLegend;
import LocationJump from "../topic/location_jump";
App.Topic.LocationJump = LocationJump;

// Charts
import Gauge from "../charts/gauge";
import Donut from "../charts/donut";
import Trend from "../charts/trend";
import Line from "../charts/line";
import SimpleBar from "../charts/simple_bar";
import SimpleLine from "../charts/simple_line";
import Area from "../charts/area";
import { colors } from "../charts/options";
App.Charts = {
  SimpleBar,
  Line,
  SimpleLine,
  Gauge,
  Donut,
  Trend,
  Area,
  Options: {
    colors
  }
};

// Water
App.Water = App.Water || {};
import DetailedContaminants from "../water/detailed_contaminants";
App.Water.DetailedContaminants = DetailedContaminants;

import bb from "billboard.js";
App.bb = bb;

import "../polyfills";

import Form from "../form/";
App.Form = Form;

import renderComponent from "../renderComponent";
App.renderComponent = renderComponent;
