import bb, { gauge } from "billboard.js";
import { colors } from "./options";

export default class {
  constructor(props) {
    this.props = props;
    this.chart = null;
    if (!("options" in this.props)) {
      this.props.options = {};
    }
    this.render();
  }

  render() {
    const self = this;
    const opts = this.props.options;
    const _options = {
      data: {
        columns: this.props.data,
        type: gauge(),
        labels: false
      },
      gauge: {
        label: {
          show: false
        }
      },
      color: {
        pattern: colors()
      },
      size: {
        height: this.props.height || 90,
        width: this.props.width || 180
      },
      bindto: this.props.el,
      axis: {
        y: { show: false },
        x: { show: false }
      }
    };
    if ("preventMouseEvents" in opts) {
      _options.onrendered = function () {
        document.querySelector(`${self.props.el} svg`).setAttribute("alt", opts.alt || "");
        this.$.chart
          .selectAll(".bb-chart-arcs path")
          .attr("style", "pointer-events: none")
          .on("mouseover", null)
          .on("mouseout", null)
          .on("mousemove", null);
      };
    }
    this.chart = bb.generate(_options);
  }
}
