import React from "react";
import { createRoot } from "react-dom/client";
import TopicHeader from "./TopicHeader";

export default class Context {
  constructor(props) {
    const _props = Object.assign(
      {
        type: "avg-trend"
      },
      props
    );
    const el = document.querySelector(props.el || "#topic-header");
    if (el) {
      const root = createRoot(el);
      root.render(<TopicHeader {..._props} />);
    }
  }
}
