import React from "react";

const normalMarkup = (
  <div>
    <p>
      No action is required. The Office of Drinking Water recommends that owners of private wells
      have their water tested annually for chemical and bacteriological contaminants.
    </p>
    <h4>Test Kit Information</h4>
    <p>
      <a href="https://www.dhss.delaware.gov/dph/hsp/privdw.html" target="_blank" rel="noreferrer">
        Delaware Division of Public Health: Drinking Water - Private Well Owners
      </a>
    </p>
  </div>
);

const sodiumMarkup = (
  <div>
    <p>
      Water softeners are the most common source of sodium in home drinking water supplies. If you
      are concerned about your water softener contributing to the sodium in your drinking water,
      talk with your doctor. One way to reduce the sodium contributed by your water softener is to
      consider dedicating one or more cold water lines, for drinking purposes, by bypassing the
      water softener. If a water softener is not installed or the cold water is already by-passing
      the water softener, you can consider: Reverse Osmosis, Distillation, De-ionization
    </p>
  </div>
);

const hardnessMarkup = (
  <div>
    <p>
      Treat hard water by adding a water softener to laundry and the dishwasher or by installing an
      ion-exchange system to treat all of your household water. Ion exchange can increase the sodium
      content of the water, which may pose health concerns for your household.
    </p>
  </div>
);

const flourideMarkup = (
  <div>
    <p>
      Home treatment methods to remove fluoride from drinking water include reverse osmosis,
      distillation, anion exchange, or activated alumina cartridges. Fluoride removal in homes
      generally uses point-of-use devices with separate faucets installed at sinks used for drinking
      water.
    </p>
    <h4>More Information</h4>
    <p>
      <a href="https://www.dhss.delaware.gov/dph/hsp/privdw.html" target="_blank" rel="noreferrer">
        Delaware Division of Public Health: Drinking Water - Private Well Owners
      </a>
    </p>
  </div>
);

const nitrateNitriteMarkup = (
  <div>
    <p>
      Reverse Osmosis for up to 30 mg/l (This does not work well when nitrate levels exceed 30
      mg/l); Ion exchange (Strong base anion exchanger)
    </p>
    <h4>More Information</h4>
    <p>
      <a href="https://www.dhss.delaware.gov/dph/hsp/privdw.html" target="_blank" rel="noreferrer">
        Delaware Division of Public Health: Drinking Water - Private Well Owners
      </a>
    </p>
  </div>
);

const eColiAndColiforms = [
  {
    name: "Normal",
    context: "(Not Present)",
    flag: "normal",
    description: () => normalMarkup
  },
  {
    name: "High",
    context: "(Present)",
    flag: "high",
    description: () => (
      <div>
        <p>
          Prior to providing treatment options, the source of fecal coliform or E. coli
          contamination should be determined and the cause of the contamination removed. Once the
          contamination has been eliminated as a threat to the water supply, disinfection of the
          private water system should be performed and the water re-sampled. It is recommended that
          the water from a private water system be tested for total coliforms and E. coli
          periodically in order to determine the need for maintenance, repair, or replacement. When
          the presence of E. coli is recurrent, it is not recommended that continuous disinfection
          be used in place of removing of the cause of contamination or repairing or replacing the
          private water system.
        </p>
        <h4>Test Kit Information</h4>
        <p>
          <a
            href="https://www.dhss.delaware.gov/dph/hsp/privdw.html"
            target="_blank"
            rel="noreferrer"
          >
            Delaware Division of Public Health: Drinking Water - Private Well Owners
          </a>
        </p>
      </div>
    )
  }
];

const LEVELS = {
  "E. Coli": eColiAndColiforms,
  "Total Coliforms": eColiAndColiforms,
  Nitrite: [
    {
      name: "Normal",
      context: "(under 10 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "High",
      context: "(over 10 mg/l)",
      flag: "high",
      description: () => nitrateNitriteMarkup
    }
  ],
  Nitrate: [
    {
      name: "Normal",
      context: "(under 10 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "High",
      context: "(over 10 mg/l)",
      flag: "high",
      description: () => nitrateNitriteMarkup
    }
  ],
  Fluoride: [
    {
      name: "Normal",
      context: "(less than 1.3 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "Borderline",
      context: "(between 1.3 mg/l and 2.0 mg/l)",
      flag: "borderline",
      description: () => flourideMarkup
    },
    {
      name: "High",
      context: "(over 2.0 mg/l)",
      flag: "high",
      description: () => flourideMarkup
    }
  ],
  Iron: [
    {
      name: "Normal",
      context: "(under 0.3 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "High",
      context: "(over 0.3 mg/l)",
      flag: "high",
      description: () => (
        <div>
          <p>
            Clear-water iron is most commonly removed with a water softener. Red-water iron can be
            removed in small quantities by a sediment filter, carbon filter, or water softener, but
            the treatment system can very quickly become blocked. A more common treatment for
            red-water iron and clear-water iron in concentrations up to 10 or 15 mg/l is a manganese
            greensand filter, often referred to as an iron filter. Aeration (injecting air) or
            chemical oxidation (usually adding chlorine in the form of calcium or sodium
            hypochlorite) followed by filtration are options if iron levels exceed 10 mg/l.
            Treatment options for elimination or reduction of iron bacteria include physical
            cleaning of the well followed by chemical disinfection, heat, and chemical treatment.
            The most common treatment is chlorination of the well and water system, however, this
            treatment option is often only temporary because the 'slime' generated by iron bacteria
            provide protection to the bacteria and regrowth occurs in the borehole.
          </p>
          <h4>More Information</h4>
          <p>
            <a
              href="https://www.dhss.delaware.gov/dph/hsp/privdw.html"
              target="_blank"
              rel="noreferrer"
            >
              Delaware Division of Public Health: Drinking Water - Private Well Owners
            </a>
          </p>
        </div>
      )
    }
  ],
  Sulfate: [
    {
      name: "Normal",
      context: "(under 250 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "High",
      context: "(over 250 mg/l)",
      flag: "high",
      description: () => (
        <div>
          <p>Three types of treatment systems will remove sulfate from drinking water:</p>
          <ul>
            <li>Reverse Osmosis</li>
            <li>Distillation</li>
            <li>Ion exchange</li>
          </ul>
          <p>
            Water softeners, carbon filters, and sediment filters do not remove sulfate. Water
            softeners merely change magnesium or calcium sulfate into sodium sulfate, which is
            somewhat more laxative.
          </p>
          <h4>More Information</h4>
          <p>
            <a
              href="https://www.dhss.delaware.gov/dph/hsp/privdw.html"
              target="_blank"
              rel="noreferrer"
            >
              Delaware Division of Public Health: Drinking Water - Private Well Owners
            </a>
          </p>
        </div>
      )
    }
  ],
  "Water Hardness": [
    {
      name: "Soft",
      context: "(under 60 ppm)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "Moderately Hard",
      context: "(between 60 ppm and 180 ppm)",
      flag: "borderline",
      description: () => hardnessMarkup
    },
    {
      name: "Very Hard",
      context: "(over 180 ppm)",
      flag: "high",
      description: () => hardnessMarkup
    }
  ],
  Chloride: [
    {
      name: "Normal",
      context: "(under 250 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "High",
      context: "(over 250 mg/l)",
      flag: "high",
      description: () => (
        <div>
          <p>Reverse osmosis, Distillation, Ion exchange</p>
        </div>
      )
    }
  ],
  Sodium: [
    {
      name: "Normal",
      context: "(under 20 mg/l)",
      flag: "normal",
      description: () => normalMarkup
    },
    {
      name: "Medium",
      context: "(between 20 mg/l and 200 mg/l)",
      flag: "borderline",
      description: () => sodiumMarkup
    },
    {
      name: "High",
      context: "(over 200 mg/l)",
      flag: "high",
      description: () => sodiumMarkup
    }
  ]
};

export default function getLevelActions(waterContaminant) {
  return LEVELS[waterContaminant];
}
